<template>
  <div>
    <vs-card>
      <div slot="header">
        <h3 class="font-medium py-4 px-10 bg-grey-background">Template Details</h3>
      </div>

      <div class="px-10 pt-6 pb-6">
      <vs-row
        class="mb-8"
        vs-align="flex-start"
        vs-justify="left"
        vs-w="12"
      >
        <vs-col vs-w="3">
          <vs-row>
            <span class="heading">Template type</span>
          </vs-row>
          <vs-row>
            <span class="answer">{{ pages.templateCategory }}</span>
          </vs-row>
        </vs-col>

        <vs-col vs-w="3">
          <vs-row>
            <span class="heading">Product name</span>
          </vs-row>
          <vs-row>
            <span class="answer">{{ pages.templateCategory }}</span>
          </vs-row>
        </vs-col>
        <vs-col vs-w="3">
          <vs-row>
            <span class="heading">Product type</span>
          </vs-row>
          <vs-row>
            <span class="answer">{{ pages.templateCategory }}</span>
          </vs-row>
        </vs-col>
      </vs-row>

      <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full mb-6">
            <span class="heading" for="Description">Description</span>

            <vs-textarea
              class="mb-4 w-15 h-auto"
              counter="300"
              maxlength="300"
              :counter-danger.sync="counterDanger"
              v-model="pages.description"
              rows="2"
              :disabled="pages.productName != 'Default'"
            />
          </div>
        </vs-col>
      </vs-row>
      </div>
    </vs-card>

    <vs-card>
      <div slot="header">
        <h3 class="font-medium py-4 px-10 bg-grey-background">Content</h3>
      </div>
      <vs-form>
        <!-- Template name -->
        <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
            v-if="!pageId">
            <div class="w-full m-5">
              <label class="label text-lg font-semibold" for="TemplateName"
                >Template Name *
              </label>
              <vs-input
                id="TemplateName"
                v-validate="'required|min:3|max:200'"
                class="w-full col-sm-4"
                data-vv-validate-on="blur"
                name="templateName"
                v-model="pages.templateName"
              >
              </vs-input>
              <span class="error">{{ errors.first("templateName") }}</span>
            </div>
          </vs-col>
        </vs-row>
      </vs-form>

      <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full py-6 px-10">
            <vs-label class="heading">Available linked content</vs-label>

            <div class="linkedContent mt-4">
              <ul>
                <li class="keywords" v-for="(item, key) in pages.keywords" :key="key">{{ item }}</li>
              </ul>
            </div>
          </div>
        </vs-col>
      </vs-row>

      <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full py-6 px-10">
            <label class="heading mb-5" for="Content">Body</label>

            <vs-textarea
              class="mb-12 w-15 mt-4 h-auto"
              counter="600"
              maxlength="600"
              :counter-danger.sync="counterDanger"
              v-model="pages.content"
              rows="8"
            />
            <span class="text-danger text-sm">{{ errors.first("content") }}</span>
          </div>
        </vs-col>
      </vs-row>

      <div class="p-4 w-full flex justify-end items-center">
        <vs-button
          class="mr-8"
          size="large"
          v-round
          @click="revertToDefault"
          align="left"
          v-if="smsTemplate.productName != 'Default'"
          >Revert to default</vs-button
        >
        <vs-button class="mr-8" size="large" v-round @click="saveSmsDetails" align="left" :disabled="errors.items.length > 0">Save</vs-button>
        <a class="flex items-center" @click="cancel">Cancel</a>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import quillEditor from "../../../Editor.vue";
import moment from "moment";
import { Validator } from "vee-validate";
const dictionary = {
  en: {
    attributes: {
      templateName: "template name",
      templateCategory: "template category"
    }
  }
};
Validator.localize(dictionary);

export default {
  name: "smsViewEditDetail",
  components: {
    quillEditor
  },
  data: () => ({
    smsTemplate: "",
    defaultSmsTemplate: "",
    categoryList: [],
    pageId: "",
    pages: {
      templateName: "",
      content: "",
      keywords: "",
      templateCategory: "",
      slug: ""
    }
  }),
  methods: {
    ...mapActions("sms", [
      "fetchSmsDetails",
      "saveSmsTemplate",
      "updateSmsDetails",
      "fetchDefaultSmsBySlug"
    ]),

    // Get sms by Id
    getSmsDetail(id) {
      this.fetchSmsDetails(id).then(res => {
        if (res.data.data) {

          this.smsTemplate = res.data.data;
          this.pages.templateName = res.data.data.templateName;
          this.pages.description = res.data.data.description;
          this.pages.content = res.data.data.content;
          this.pages.subject = res.data.data.subject;
          this.pages = res.data.data;
        }
      }).catch(()=>{});
    },

    revertToDefault() {
      let data = {
        slug: this.smsTemplate.slug,
        productType: this.smsTemplate.productType
      };

      this.fetchDefaultSmsBySlug(data).then(res => {
        this.defaultSmsTemplate = res.data.data;

        this.pages.content = res.data.data.content;
        this.pages.subject = res.data.data.subject;
        this.pages.templateName = res.data.data.templateName;
        this.pages.description = res.data.data.description;
        this.pages.keywords = res.data.data.keywords;
        this.pages.templateCategory = res.data.data.templateCategory;
      }).catch(()=>{});
    },

    async chooseTemplateCategory(event, templateCategory) {
      if (!this.$route.params.id) {
        this.pages.keywords = event.keywords;
        this.pages.slug = event.slug;
      }
    },

    saveSmsDetails() {
      this.$validator.validateAll().then(success => {
        if (!success) {
          return false;
        }
        let id = this.$route.params.id;
        let info = this.pages;

        if (!id) {
          this.saveSmsTemplate(info).then(res => {
            this.$vs.notify({
              subject: "Update Page Content",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",

            });
            this.$router.push({ name: "email-templates" });
          });
        }

        if (id) {
          info = {
            content: this.pages.content,
            description: this.pages.description,
          };

          this.updateSmsDetails({ id, info }).then(res => {
            this.$vs.notify({
              subject: "Update Page Content",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",

            });
            this.redirectPage();
          }).catch((ex) => {
            this.$vs.notify({
              subject: "Failed",
              text: ex.response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
        }
      });
    },

    cancel() {
      this.redirectPage();
    },

    redirectPage() {
      if (this.$route.query.planId) {
        this.$router.push({
          name: "pay-later-product",
          query: { id: this.$route.query.planId, productType: this.$route.query.productType, template: true },
        });
      } else {
        this.$router.push({ name: "email-templates" });
      }
    },

    handleContentValidation() {
      if (this.pages.content.length) {
        this.errors.remove("content");
      } else if (!this.errors.has("content")) {
        this.errors.add({
          "field": "content",
          "msg": "The body field is required"
        });
      }
    }
  },

  created() {
    this.pageId = this.$route.params.id;
    if (this.pageId) {
      this.getSmsDetail(this.pageId);
    }
  },

  watch: {
    "pages.content"(val) {
      this.handleContentValidation();
    }
  }
};
</script>
