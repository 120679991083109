<template>
  <quill-editor
    v-model="pages.content"
    ref="quillEditor"
    :options="editorOption"
    @focus="onEditorFocus($event)"
    @change="onEditorChange($event)"
    @blur="onEditorBlur($event)"
  >
  </quill-editor>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: { quillEditor },
  props: ["pages"],
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ "list": "ordered" }, { "list": "bullet" }],
            [{ "script": "sub" }, { "script": "super" }],
            [{ "header": [1, 2, 3, 4, 5, 6, false] }],
            [{ "color": [] }, { "background": [] }],
            [{ "align": [] }],
            ["link", "image"]
          ],
        },
      },
    };
  },

  computed: {
    editor() {
      return this.$refs.quillEditor.quill;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    insertTextAtCursor(text) {
      let selection = this.editor.getSelection(true);
      this.editor.insertText(selection.index, text);
    },

    onEditorChange({ quill, html, text }) {
      this.content = html;
      this.$emit("editorChanged", { quill, html, text });
    },

    onEditorFocus(quill) {
      this.$emit("isEditorFocused", quill);
    },

    onEditorBlur(quill) {
      this.$emit("onEditorBlur", quill);
    },
  },
};
</script>
